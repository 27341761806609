import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from '../../components/localizedLink'
import Img from 'gatsby-image'
import {filterByLang, slugify} from '../../utility/utils'
import {FormattedMessage} from 'react-intl'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import pages from '../../utility/pages';


const Corsi = ({ pageContext: { locale } })  => {
  const { allContentfulContenutoGenerico, allContentfulCorsiNew } = useStaticQuery(
    graphql`
      query {
        allContentfulContenutoGenerico(filter: {contentful_id: {eq: "79LJt5B0NQnRgntyIgHcyH"}}) {
          edges {
            node {
              node_locale
              testo {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
        }  
        allContentfulCorsiNew(sort: {fields: data, order: DESC}) {
          edges {
            node {
              titolo
              data(formatString: "DD MMMM, YYYY", locale:"it_IT")
              node_locale
              abstract {
                childMarkdownRemark {
                  html
                }
              }
              copertina {
                title
                fluid(maxWidth: 450) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
          }
        }
      }
    `
  ); 
  const corsi = filterByLang(allContentfulCorsiNew,locale);
 
  const intro = filterByLang(allContentfulContenutoGenerico,locale)[0];
  return (
    <Layout locale={locale}>
      <SEO title="corsi" />
      <div className="row">
        <div className="col-12 text-center">
          <FormattedMessage id="corsi">
              {txt => <h1 className="pagetitle">{txt}</h1>}
          </FormattedMessage>
        </div>
      </div>

      {intro.testo &&
        <div className="section section--top">
          <div className="row">
            <div className="col-12 ">
              <div className="section__text"  dangerouslySetInnerHTML={{__html: intro.testo.childMarkdownRemark.html}} />
            </div>
          </div>
        </div>
      }
      <div className="section section--top">
        <div className="row equal">
          {corsi.map((item,index) => { 
              return (
                <div key={index} className="col-sm-12 col-md-6 d-flex">
                  <div className="corsi__card">
                    {!!item.copertina  &&
                    <Link  to={`${pages[locale]('/corsi/')}${slugify(item.titolo)}/`}>
                      <Img alt={item.titolo} title={item.titolo} className="corsi__copertina" fluid={item.copertina.fluid} />
                    </Link>  
                    }
                    <div className="p-3">
                      <div className="corsi__title">
                        <Link to={`${pages[locale]('/corsi/')}${slugify(item.titolo)}/`}>{item.titolo}</Link>
                      </div> 
                      {/* <div className="corsi__date">
                        {item.data}
                      </div> */}
                      <div className="corsi__text"  dangerouslySetInnerHTML={{__html: item.abstract.childMarkdownRemark.html}} />
                      <div className="text-center corsi__read">
                        <Link to={`${pages[locale]('/corsi/')}${slugify(item.titolo)}/`} className="btn-action btn-action--small">Leggi</Link>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }
       </div>
      </div>
    </Layout>
  )
}
export default Corsi